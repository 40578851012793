package matekit.website

import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.material3.Button
import androidx.compose.material3.Card
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import kotlinx.browser.window
import matekit.website.ui.ratios
import matekitwebsite.composeapp.generated.resources.Res
import matekitwebsite.composeapp.generated.resources.support_me_on_kofi_red
import matekitwebsite.composeapp.generated.resources.support_us
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun webapp(
    ratios: ratios
){
    Card(
        Modifier.wrapContentHeight().fillMaxWidth(ratios.horizontal)
    )
    {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier.padding(ratios.padding).fillMaxWidth()
        ) {
            Text("Elérhető online webalkalmazásként!\n", fontSize =ratios. secondaryfontsize, lineHeight =ratios.  secondarylineheight)
          orangeButton(
              text = "app.matekit.hu",
              onClick = {
                window.open("https://app.matekit.hu", "_blank")
              },
              font1 = ratios.tertiaryfontsize,
              lineheight = ratios.tertiarylineheight
          )
    }
}
}