package matekit.website

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.material3.Card
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import kotlinx.browser.window
import matekit.website.ui.elrendezes
import matekit.website.ui.ratios
import matekitwebsite.composeapp.generated.resources.Res
import matekitwebsite.composeapp.generated.resources.facebook_logo
import matekitwebsite.composeapp.generated.resources.link
import matekitwebsite.composeapp.generated.resources.mail_24dp
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.vectorResource


@Composable
fun kapcsolat(
 ratios: ratios
) {
    Card(
        modifier = Modifier.wrapContentHeight().fillMaxWidth(ratios.horizontal)
    ) {
        Column(
            Modifier.padding(ratios.padding).wrapContentHeight().fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                Icon(
                    painterResource(Res.drawable.link),
                    null,
                    modifier = Modifier.size(40.dp)
                )
                Spacer(Modifier.width(8.dp))
                secondaryText("Kapcsolat", ratios.secondaryfontsize)
            }
            Spacer(ratios.space)
            Row(verticalAlignment = Alignment.CenterVertically) {
                 Icon(
                     painterResource(Res.drawable.facebook_logo),
                     contentDescription = "facebook",
                     modifier = Modifier.size(80.dp).clickable {
                         window.open("https://www.facebook.com/matekkit/")
                     }
                 )
               /* Spacer(Modifier.width(8.dp))
                orangeButton(onClick = {
                }, text = "Kövess minket Facebook-on!")*/
            }
            Spacer(ratios.halfspace)
       if(ratios.elrendezes==elrendezes.Horizontal) {
           Row(verticalAlignment = Alignment.CenterVertically) {
               Icon(
                   vectorResource(Res.drawable.mail_24dp),
                   contentDescription = "email",
                   modifier = Modifier.size(80.dp)
               )
               Spacer(Modifier.width(8.dp))
               Text("Email:", fontSize = ratios.secondaryfontsize)
               Spacer(Modifier.width(8.dp))
               EmailButton(ratios.secondaryfontsize)
           }
       }else{
           Column(horizontalAlignment = Alignment.CenterHorizontally, verticalArrangement = Arrangement.Center) {
               Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center,modifier=Modifier.fillMaxWidth()){
                   Icon(
                       vectorResource(Res.drawable.mail_24dp),
                       contentDescription = "email",
                       modifier = Modifier.size(80.dp)
                   )
                   Spacer(Modifier.width(8.dp))
                   Text("Email:", fontSize = ratios.secondaryfontsize)
               }
               Spacer(Modifier.width(8.dp))
               EmailButton(ratios.secondaryfontsize)
           }
       }




        }
    }
}