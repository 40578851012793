package matekit.website

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.OutlinedCard
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import matekit.website.ui.elrendezes
import matekit.website.ui.ratios
import matekitwebsite.composeapp.generated.resources.Melinda_CV_I
import matekitwebsite.composeapp.generated.resources.Melinda_circle
import matekitwebsite.composeapp.generated.resources.KovacsJanos_circle

import matekitwebsite.composeapp.generated.resources.Res
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun Rolunk(ratios: ratios){

Card(
    modifier = Modifier.fillMaxWidth(ratios.horizontal)

){
if(ratios.elrendezes== elrendezes.Horizontal)   Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.padding(ratios.smallpadding)) {
    ContentMelinda(ratios)
   }
    else Column(horizontalAlignment = Alignment.CenterHorizontally, modifier = Modifier.padding(ratios.smallpadding)) {
        ContentMelinda(ratios)
    }
    Spacer(ratios.space)
    if(ratios.elrendezes== elrendezes.Horizontal)   Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.padding(ratios.smallpadding)) {
        ContentJános(ratios)
    }
    else Column(horizontalAlignment = Alignment.CenterHorizontally, modifier = Modifier.padding(ratios.smallpadding)) {
        ContentJános(ratios)
    }
}
}

@Composable
fun ContentMelinda(ratios:ratios){
    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = Modifier.padding(8.dp)
    ) {
        Image(painter = painterResource(Res.drawable.Melinda_circle),modifier= Modifier.clip(
            RoundedCornerShape(16.dp)
        ).size(200.dp),contentDescription = "Csóti Melinda")
        Text("Csóti Melinda")
        Text("Matematikatanár", textAlign = TextAlign.Center)
    }
    OutlinedCard(
        Modifier.padding(ratios.padding)
    ) {
        Column(
            modifier=Modifier.wrapContentHeight().fillMaxWidth().padding(ratios.padding)
        ) {
            Text(
                stringResource(Res.string.Melinda_CV_I), modifier = Modifier.fillMaxWidth(),
                textAlign = TextAlign.Justify
            )
        }
    }
}

@Composable
fun ContentJános(ratios:ratios){
    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = Modifier.padding(8.dp)
    ) {
        Image(painter = painterResource(Res.drawable.KovacsJanos_circle),modifier= Modifier.clip(
            RoundedCornerShape(16.dp)
        ).size(200.dp),contentDescription = "Kovács János")
        Text("Kovács János")
        Text("Magyar Fiatal Tudósok Társaságának tagja", textAlign = TextAlign.Center)
        Text("Szegedi Tudományegyetem\nMolekuláris Bionika Mérnök hallgatója", textAlign = TextAlign.Center)
    }
    OutlinedCard(
        Modifier.padding(ratios.padding)
    ) {
        Column(
            modifier=Modifier.wrapContentHeight().fillMaxWidth().padding(ratios.padding)
        ) {
            Text(
                "Hatodik osztályos diákként kezdtem programozást tanulni, mikor a Dr. Code programozóiskola első kezdő kurzusait elindította általános iskolámban, és addigi tanulmányi eredményeimnek köszönhetően felajánlottak nekem egy ösztöndíjas helyet. A kurzusokon először Scratch-ben sajátítottuk el az algoritmikus gondolkozás alapjait, majd MIT App Inventorban tanultunk Android alkalmazás fejlesztést. Végül egy webfejlesztő kurzuson a HTML és JavaScript alapjait ismertem meg. Ezek az ismeretek adták az eszköztáramat, (melyek mindegyikét tudtam hasznosítani időközben) mikor 2019-ben elkezdtünk dolgozni a MateKIT-en. Célként azt fogalmaztuk meg, hogy az iskolákban akkor újonnan rendelkezésre álló okostáblák és táblagépek felhasználásával válaszoljunk a differenciált oktatás kihívásaira. A MateKIT fejlesztését MIT App Inventorban kezdtem, viszont hamar szembesültem a platform korlátaival. Némi utánajárás után az új Kotlin programozási nyelvre esett a választás, mely abban az évben lett a Google által hivatalosan ajánlott Android programozási nyelv. Így a MateKIT fejlesztéséhez azóta mindig a legmodernebb keretrendszert alkalmazzuk, követve a technológia fejlődését.", modifier = Modifier.fillMaxWidth(),
                textAlign = TextAlign.Justify
            )
        }
    }
}