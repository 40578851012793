package matekit.website

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.fadeIn
import androidx.compose.animation.slideInHorizontally
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import matekit.website.ui.ratios
import matekitwebsite.composeapp.generated.resources.Res
import matekitwebsite.composeapp.generated.resources.ic_gl7
import org.jetbrains.compose.resources.painterResource

@Composable
fun top(
    ratios: ratios,
    visibility:MutableState<Boolean>
){

    AnimatedVisibility(visibility.value, enter = slideInHorizontally(
        initialOffsetX = { fullWidth -> fullWidth } // Start from the right
    ) + fadeIn()) {
        Image(
            painter = painterResource(Res.drawable.ic_gl7),
            contentDescription = "logo",
            modifier = Modifier.size(240.dp)
        )
    }
    Spacer(ratios.halfspace)

    AnimatedVisibility(visibility.value, enter = slideInHorizontally(
        initialOffsetX = { fullWidth -> -fullWidth } // Start from the left
    ) + fadeIn()) {
        OrangeCard {
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                modifier = Modifier.padding(ratios.padding)
            ) {
                Text(
                    "MateKIT",
                    style = MaterialTheme.typography.titleLarge,
                    textAlign = TextAlign.Center, fontSize = ratios.largeTitle,lineHeight = ratios.largeTitleheight
                )
                Text(
                    "Ingyenes Díjnyertes",
                    style = MaterialTheme.typography.titleLarge,
                    fontSize = ratios.titlefontsize,
                    lineHeight = ratios.titlelineheight,
                    textAlign = TextAlign.Center
                )
                Text(
                    "Matematikai Oktatóprogram",
                    style = MaterialTheme.typography.titleLarge,
                    fontSize = ratios.titlefontsize,
                    lineHeight = ratios.titlelineheight,
                    textAlign = TextAlign.Center
                )

            }
        }

    }


}