package matekit.website

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.Spring
import androidx.compose.animation.core.SpringSpec
import androidx.compose.foundation.Image
import androidx.compose.foundation.ScrollState
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.NavigationRail
import androidx.compose.material3.NavigationRailItem
import androidx.compose.material3.Text
import androidx.compose.material3.Button
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.NavigationBar
import androidx.compose.material3.NavigationBarItem
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Surface

import androidx.compose.runtime.*
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.positionInParent
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import matekit.website.ui.elrendezes
import matekit.website.ui.matekitcolor
import matekit.website.ui.mediumdeviceratios
import matekit.website.ui.pageTheme
import matekit.website.ui.ratios
import matekit.website.ui.smalldeviceratios
import org.jetbrains.compose.resources.painterResource

import matekitwebsite.composeapp.generated.resources.Res
import matekitwebsite.composeapp.generated.resources.calculate
import matekitwebsite.composeapp.generated.resources.controller
import matekitwebsite.composeapp.generated.resources.download_24dp
import matekitwebsite.composeapp.generated.resources.home
import matekitwebsite.composeapp.generated.resources.group

import matekitwebsite.composeapp.generated.resources.ic_android_black_24dp
import matekitwebsite.composeapp.generated.resources.ic_gl7
import matekitwebsite.composeapp.generated.resources.ifj_verseny
import matekitwebsite.composeapp.generated.resources.ismerteto
import matekitwebsite.composeapp.generated.resources.kofi
import matekitwebsite.composeapp.generated.resources.link
import matekitwebsite.composeapp.generated.resources.otp_fay_innovaciosdij_small
import matekitwebsite.composeapp.generated.resources.sziiv_logo_nagy
import matekitwebsite.composeapp.generated.resources.trophy
import matekitwebsite.composeapp.generated.resources.windows_logo___2021
import matekitwebsite.composeapp.generated.resources.play_arrow_24dp
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.stringResource


@Composable
fun App() {



   // val width = remember { mutableStateOf(window.innerWidth) }
    val density = LocalDensity.current

    val windowWidthPx = window.innerWidth

    val widthDp = with(density) { windowWidthPx.toDp() }
    val width = remember { mutableStateOf(widthDp) }


    val ratios by derivedStateOf {
        if (width.value < 600.dp) {
            smalldeviceratios

        } else if (width.value >= 600.dp && width.value < 1200.dp) {
            mediumdeviceratios

        } else {
            ratios()
        }
    }
    var scroll = rememberScrollState()

    val coroutineScope = rememberCoroutineScope()
    val visibility = remember { mutableStateOf(false) }


    var programrol = remember { mutableStateOf(0) }
    var elismeresekOffset = remember { mutableStateOf(0) }
    var feladatgenerátorokscroll = remember { mutableStateOf(0) }
    var gamesscroll = remember { mutableStateOf(0) }
    var letoltes = remember { mutableStateOf(0) }
    var ajanlasok = remember { mutableStateOf(0) }
    var velemenyek = remember { mutableStateOf(0) }
    var rolunk = remember { mutableStateOf(0) }
    var támogassminket = remember { mutableStateOf(0) }
    var kapcsolat = remember { mutableStateOf(0) }
    var viewportHeight = remember { mutableStateOf(0) }
    var webappoffset= remember { mutableStateOf(0) }


    val selectedindex = remember { mutableStateOf(86) }

    LaunchedEffect(width.value) {
        /*println("density = ${density.density}")
        println("width changed to ${width.value}")
        println("ratios = ${ratios.widthtype}")*/
    }
    pageTheme {
Scaffold(
    modifier = Modifier.onGloballyPositioned { coordinates ->
        viewportHeight.value = coordinates.size.height
    },
    bottomBar = {

        if (
            ratios.widthtype == matekit.website.ui.widthtype.small
        ) {
            BottomNavigationBar(
                scroll,
                coroutineScope,
                viewportHeight = viewportHeight,
                programrol = programrol,
                kapcsolat = kapcsolat,
                letoltes = letoltes,
            )
        }
    }

) {innerPadding ->


        Surface(modifier = Modifier.fillMaxSize()) {


            Column(Modifier.fillMaxSize().padding(innerPadding)){
                Row(Modifier.fillMaxSize()) {


                    /*LaunchedEffect(scroll.value) {
                        println("a scroll értéke= ${scroll.value}")
                    }*/

                    if (ratios.widthtype == matekit.website.ui.widthtype.medium || ratios.widthtype == matekit.website.ui.widthtype.large) {
                        NavigationRailExample(
                            scroll,
                            coroutineScope,
                            elismeresekOffset = elismeresekOffset,
                            letoltes = letoltes,
                            programrol = programrol,
                            feladatgenerátorokscroll = feladatgenerátorokscroll,
                            gamesscroll = gamesscroll,
                            ajanlasok = ajanlasok,
                            velemenyek = velemenyek,
                            rolunk = rolunk,
                            támogassminket = támogassminket,
                            kapcsolat = kapcsolat,
                            viewportHeight = viewportHeight,
                            webappoffset = webappoffset
                        )
                    }


                    LaunchedEffect(Unit) {
                        delay(150)
                        visibility.value = true
                    }

                    Column(
                        Modifier
                            .fillMaxHeight()
                            .weight(1f)
                            .padding(ratios.padding)
                            .verticalScroll(scroll),
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {

                        if (ratios.elrendezes == elrendezes.Horizontal) {
                            Row(
                                horizontalArrangement = Arrangement.Center,
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                top(ratios, visibility)
                            }
                        } else {
                            Column(
                                horizontalAlignment = Alignment.CenterHorizontally,
                                verticalArrangement = Arrangement.Center,
                                modifier = Modifier.wrapContentSize()
                            ) {
                                top(ratios, visibility)
                            }
                        }

                        Spacer(ratios.space)

                        AnimatedVisibility(
                            visibility.value,
                            Modifier.onGloballyPositioned { coordinates ->
                                webappoffset.value = coordinates.positionInParent().y.toInt()
                            }
                        ) {
                            webapp(ratios)
                        }
                        Spacer(ratios.space)

                        AnimatedVisibility(
                            visibility.value,
                            Modifier.onGloballyPositioned { coordinates ->
                                programrol.value = coordinates.positionInParent().y.toInt()
                            }
                        ) {
                            Card()
                            {
                                Column(
                                    Modifier.padding(ratios.largepadding).fillMaxWidth(ratios.horizontal)
                                        .wrapContentHeight(),
                                    horizontalAlignment = Alignment.CenterHorizontally,
                                ) {
                                    secondaryText("A programról", ratios.secondaryfontsize)
                                    Spacer(ratios.halfspace)
                                    Text(
                                        stringResource(Res.string.ismerteto),
                                        style = MaterialTheme.typography.bodyLarge,
                                        fontSize = ratios.tertiaryfontsize, lineHeight = ratios.tertiarylineheight,textAlign = ratios.textAlign
                                        //   color = Color.Black
                                    )
                                }
                            }
                        }



                        Spacer(ratios.space)
                        AnimatedVisibility(
                            visibility.value,
                            Modifier.onGloballyPositioned { coordinates ->
                                elismeresekOffset.value = coordinates.positionInParent().y.toInt()
                            }) {
                            Card(
                                modifier = Modifier.padding(ratios.padding)
                            ) {
                                Column(
                                    Modifier.padding(ratios.padding),
                                    horizontalAlignment = Alignment.CenterHorizontally,
                                ) {
                                    val horizontal = rememberScrollState()
                                    secondaryText("Elismerések", ratios.secondaryfontsize)
                                    Spacer(ratios.halfspace)
                                    //Modifier.horizontalScroll(horizontal)
                                    //OTP oktatási Innovációs díj
                                    //SZIIV I.  díj::  Szegedi Tudományegyetem IX. Szegedi Innovatív Informatika Verseny
                                    //OTIO II. Díj 	31. IFJÚSÁGI TUDOMÁNYOS ÉS INNOVÁCIÓS TEHETSÉGKUTATÓ VERSENY
                                    Award(
                                        title = "Szegedi Tudományegyetem\nIX. Szegedi Innovatív Informatika Verseny",
                                        helyezés = "I. Díj",
                                        image = Res.drawable.sziiv_logo_nagy,
                                        ratios

                                    )
                                    Spacer(ratios.halfspace)
                                    Award(
                                        title = "31. Országos Tudományos és Innovációs Olimpia",
                                        helyezés = "II. Díj",
                                        image = Res.drawable.ifj_verseny, ratios
                                    )

                                    Spacer(ratios.halfspace)
                                    Row() {
                                        Award(
                                            title = "OTP Fáy Oktatási Innovációs Díj",
                                            helyezés = "Ezüst Fokozat",
                                            image = Res.drawable.otp_fay_innovaciosdij_small, ratios
                                        )
                                    }
                                }
                            }
                        }


                        val vélemények = listOf("")//ide vélemények
                        //chunkoljuk a véleményeket valahány darabonként és rakjunk ki belőlük egy szép nagy falat ide
                        //todo vélemények

                        AnimatedVisibility(
                            visibility.value,
                            Modifier.onGloballyPositioned { coordinates ->
                                feladatgenerátorokscroll.value =
                                    coordinates.positionInParent().y.toInt()
                            }
                        ) { CustomGrid(feladatgenerátorok, selectedindex, ratios) }
                        Spacer(ratios.halfspace)
                        AnimatedVisibility(
                            visibility.value,
                            Modifier.onGloballyPositioned { coordinates ->
                                gamesscroll.value = coordinates.positionInParent().y.toInt()
                            }
                        ) { CustomGrid(games, selectedindex, ratios) }
                        Spacer(ratios.space)
                        // ModuleGrid(feladatgenerátorok,selectedindex)
                        //todo játékok
                        AnimatedVisibility(
                            visibility.value,
                            Modifier.onGloballyPositioned { coordinates ->
                                letoltes.value = coordinates.positionInParent().y.toInt()
                                // println("letoltes scroll  assigned to ${letoltes.value}")
                            }) {
                            Card(
                                modifier = Modifier.padding(ratios.padding).wrapContentSize()
                            ) {
                                Column(
                                    Modifier.padding(ratios.padding).height(300.dp).width(400.dp),
                                    horizontalAlignment = Alignment.CenterHorizontally,
                                    verticalArrangement = Arrangement.Center
                                ) {
                                    Row(verticalAlignment = Alignment.CenterVertically) {
                                        Icon(
                                            painterResource(Res.drawable.download_24dp),
                                            null,
                                            modifier = Modifier.size(40.dp)
                                        )
                                        secondaryText("Letöltések", ratios.secondaryfontsize)
                                        Spacer(Modifier.width(8.dp))

                                    }
                                    Spacer(ratios.space)
                                    Row(verticalAlignment = Alignment.CenterVertically) {
                                        Icon(
                                            painterResource(Res.drawable.ic_android_black_24dp),
                                            contentDescription = "android",
                                            modifier = Modifier.size(80.dp)
                                        )
                                        Spacer(Modifier.width(8.dp))
                                        orangeButton(
                                            onClick = {
                                                window.open("https://play.google.com/store/apps/details?id=matekit.matekit")
                                            },
                                            text = "Android",
                                            font1 = ratios.secondaryfontsize,
                                            lineheight = ratios.secondarylineheight
                                        )
                                    }
                                    Spacer(ratios.halfspace)
                                    Row(verticalAlignment = Alignment.CenterVertically) {
                                        Icon(
                                            painterResource(Res.drawable.windows_logo___2021),
                                            contentDescription = "windows",
                                            modifier = Modifier.size(80.dp)
                                        )
                                        Spacer(Modifier.width(8.dp))

                                        orangeButton(
                                            onClick = {
                                                window.open("https://github.com/MateKIT/MateKIT/releases")
                                            },
                                            text = "Windows 10/11",
                                            font1 = ratios.secondaryfontsize,
                                            lineheight = ratios.secondarylineheight
                                        )
                                    }


                                }
                            }
                        }


                        Spacer(ratios.space)
                        AnimatedVisibility(
                            visibility.value,
                            Modifier.onGloballyPositioned { coordinates ->
                                rolunk.value = coordinates.positionInParent().y.toInt()
                            }) {
                            Rolunk(ratios)
                        }
                        Spacer(ratios.space)

                        AnimatedVisibility(
                            visibility.value,
                            Modifier.onGloballyPositioned { coordinates ->
                                támogassminket.value = coordinates.positionInParent().y.toInt()
                            }) {
                            SupportUs(
                                ratios
                            ) //támogatás //tamogatas
                        }
                        Spacer(ratios.space)
                        AnimatedVisibility(
                            visibility.value,
                            Modifier.onGloballyPositioned { coordinates ->
                                kapcsolat.value = coordinates.positionInParent().y.toInt()
                            }) {
                            kapcsolat(
                                ratios
                            )
                        }


                    }


                }
            }
        }

    }
}

}


@Composable
fun EmailButton(fontsize: TextUnit) {
    val email = "matekit.dev@gmail.com"
    val annotatedString = AnnotatedString.Builder(email)
        .apply {
            addStyle(
                style = SpanStyle(
                    color = Color.Blue,
                    textDecoration = TextDecoration.Underline
                ),
                start = 0,
                end = email.length
            )
        }
        .toAnnotatedString()

    Button(
        colors = androidx.compose.material3.ButtonDefaults.buttonColors(
            containerColor = matekitcolor,
            // contentColor = Color.White
        ),
        onClick = {
            window.open("mailto:$email", "_blank")
        },
        modifier = Modifier.wrapContentSize()
    ) { Text(text = annotatedString, fontSize = fontsize, maxLines = 1) }
}

@Composable
fun Quotation(
    Quotation: String,
    Author: String
) {
    Card(
        elevation = CardDefaults.cardElevation()
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center,
            modifier = Modifier.padding(16.dp).size(200.dp),
        ) {
            Text(Quotation, textAlign = TextAlign.Center)
            Text(Author, textAlign = TextAlign.Center)
        }
    }
}
/*
val space = Modifier.height(64.dp).width(64.dp)
val halfspace = Modifier.height(32.dp).width(32.dp)
val largeTitle = 72.sp
val titlefontsize = 44.sp
val titlelineheight = 60.sp
val secondaryfontsize = 32.sp
val secondarylineheight = 40.sp
val tertiaryfontsize = 24.sp*/


@Composable //nem biztos, hogy mindegyik ki fog ugyanúgy  férni
fun Award(
    title: String = "",
    helyezés: String = "",
    image: DrawableResource,
    ratios: ratios
) {
    OrangeCard(Modifier.fillMaxWidth(ratios.horizontal)) {
        if(ratios.elrendezes== elrendezes.Horizontal){
            Row(
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center,
                modifier = Modifier.padding(16.dp).fillMaxSize()
            ) {
                Image(
                    painterResource(image),
                    contentDescription = "logo",
                    modifier = Modifier.size(240.dp).clip(CircleShape)
                )
                Spacer(Modifier.width(16.dp))
                Column(horizontalAlignment = Alignment.CenterHorizontally) {
                    Text(
                        title,
                        textAlign = TextAlign.Center,
                        fontSize = ratios.tertiaryfontsize,
                        lineHeight = ratios.tertiarylineheight
                    )
                    Text(
                        helyezés,
                        textAlign = TextAlign.Center,
                        fontSize = ratios.secondaryfontsize,
                        lineHeight = ratios.secondarylineheight
                    )
                }
            }
        }else{
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center,
                modifier = Modifier.padding(16.dp).fillMaxSize()
            ) {
                Image(
                    painterResource(image),
                    contentDescription = "logo",
                    modifier = Modifier.size(160.dp).clip(CircleShape)
                )
                Spacer(Modifier.width(16.dp))
                Column(horizontalAlignment = Alignment.CenterHorizontally) {
                    Text(
                        title,
                        textAlign = TextAlign.Center,
                        fontSize = ratios.tertiaryfontsize,
                        lineHeight = ratios.tertiarylineheight
                    )
                    Text(
                        helyezés,
                        textAlign = TextAlign.Center,
                        fontSize = ratios.secondaryfontsize,
                        lineHeight = ratios.secondarylineheight
                    )
                }
            }
        }



    }
}


@Composable
fun OrangeCard(modifier: Modifier = Modifier, content: @Composable () -> Unit) {
    Card(
        colors = CardDefaults.cardColors(
            containerColor = matekitcolor,
        ),
        shape = CardDefaults.elevatedShape,
        modifier = modifier
    ) {
        content()

    }
}


@Composable
fun orangeButton(text: String, onClick: () -> Unit, font1: TextUnit, lineheight: TextUnit) {
    Button(
        onClick = { onClick() },
        colors = androidx.compose.material3.ButtonDefaults.buttonColors(
            containerColor = matekitcolor,
            // contentColor = Color.White
        )
    ) {
        Text(
            text,
            fontSize = font1,
            color = Color.White,
            lineHeight = lineheight,
            textAlign = TextAlign.Center
        )
    }
}

@Composable
fun secondaryText(text: String, fontSize: TextUnit) {
    Text(text, style = MaterialTheme.typography.titleMedium, fontSize = fontSize)
}




@Composable
fun BottomNavigationBar(
    scrollState: ScrollState,
    coroutineScope: CoroutineScope,
    viewportHeight: MutableState<Int>,
    programrol: MutableState<Int>,
    letoltes: MutableState<Int>,
    kapcsolat: MutableState<Int>

    ) {
    NavigationBar(
        modifier = Modifier.height(56.dp)
    ) {
        var selectedItem by rememberSaveable { mutableStateOf(0) }
        val items = listOf(
            NavigationItem("", Res.drawable.home, mutableStateOf(0)),
            NavigationItem("A Programról", Res.drawable.calculate, programrol),
            // NavigationItem("Ajánlások", Res.drawable.reviews),
            //  NavigationItem("Vélemények", Res.drawable.forum),
            NavigationItem("Letöltések", Res.drawable.download_24dp, letoltes),
            NavigationItem("Kapcsolat", Res.drawable.link, kapcsolat),
        )
        LaunchedEffect(Unit) {
            selectedItem = 0
            delay(1000)

            /*  println("viewport height = ${viewportHeight.value}")
              println("scroll state = ${scrollState.value}")
              println("programrol = ${programrol.value}")
              println("elismeresekOffset = ${elismeresekOffset.value}")
              println("letoltes = ${letoltes.value}")*/
        }
        LaunchedEffect(scrollState.value) {
            val tolerance = 60
            // Calculate the center of the viewport
            val viewportCenter = viewportHeight.value / 2
            //  println("viewportcenter = $viewportCenter")
            // println("scroll state = ${scrollState.value}")
            items.forEachIndexed { index, navigationItem ->
                // Adjust the condition to check if the item is near the center of the viewport
                val itemCenterPosition = navigationItem.offset.value - 60 //+ viewportCenter
                if (
                    scrollState.value in (itemCenterPosition - tolerance)..(itemCenterPosition + tolerance)
                ) {
                    selectedItem = index
                }
            }
            if (scrollState.value == 0) {
                selectedItem = 0
            }
        }



        items.forEachIndexed { index, item ->
            NavigationBarItem(
                icon = {
                    Icon(
                        painterResource(item.icon),
                        contentDescription = item.label,
                        modifier = Modifier.size(26.dp),
                        tint = if (selectedItem == index) matekitcolor else LocalContentColor.current
                    )
                },
                label = { Text(item.label, fontSize = 12.sp) },
                selected = selectedItem == index,
                onClick = {
                    selectedItem = index

                    coroutineScope.launch {
                        when (item.label) {
                            "" -> scrollState.animateScrollTo(
                                0, SpringSpec(
                                    dampingRatio = Spring.DampingRatioLowBouncy,
                                    stiffness = Spring.StiffnessLow
                                )
                            )

                            "Letöltések" -> {
                                scrollState.animateScrollTo(
                                    letoltes.value,
                                    SpringSpec(
                                        dampingRatio = Spring.DampingRatioLowBouncy,
                                        stiffness = Spring.StiffnessLow
                                    )
                                )
                                //println("letoltes scroll = ${letoltes.value}")
                            }

                            "A Programról" -> scrollState.animateScrollTo(
                                programrol.value, SpringSpec(
                                    dampingRatio = Spring.DampingRatioLowBouncy,
                                    stiffness = Spring.StiffnessLow
                                )
                            )







                            "Kapcsolat" -> scrollState.animateScrollTo(
                                kapcsolat.value, SpringSpec(
                                    dampingRatio = Spring.DampingRatioLowBouncy,
                                    stiffness = Spring.StiffnessLow
                                )
                            )
                        }
                    }

                },
                //telefonon kisebb ikonok
            )
        }
    }
}


@Composable
fun NavigationRailExample(
    scrollState: ScrollState,
    coroutineScope: CoroutineScope,
    elismeresekOffset: MutableState<Int>,
    viewportHeight: MutableState<Int>,
    programrol: MutableState<Int>,
    letoltes: MutableState<Int>,
    feladatgenerátorokscroll: MutableState<Int>,
    gamesscroll: MutableState<Int>,
    ajanlasok: MutableState<Int>,
    velemenyek: MutableState<Int>,
    támogassminket: MutableState<Int>,
    rolunk: MutableState<Int>,
    kapcsolat: MutableState<Int>,
    webappoffset: MutableState<Int>
) {
    var selectedItem by rememberSaveable { mutableStateOf(0) }
    val items = listOf(
        NavigationItem("", Res.drawable.home, mutableStateOf(0)),
        NavigationItem("Webalkalmazás", Res.drawable.play_arrow_24dp, webappoffset),
        NavigationItem("A Programról", Res.drawable.ic_gl7, programrol),
        NavigationItem("Elismerések", Res.drawable.trophy, elismeresekOffset),
        NavigationItem("Feladatgenerátorok", Res.drawable.calculate, feladatgenerátorokscroll),
        NavigationItem("Játékok", Res.drawable.controller, gamesscroll),
        // NavigationItem("Ajánlások", Res.drawable.reviews),
        //  NavigationItem("Vélemények", Res.drawable.forum),
        NavigationItem("Letöltések", Res.drawable.download_24dp, letoltes),
        NavigationItem("Rólunk", Res.drawable.group, rolunk),
        NavigationItem("Támogass minket!", Res.drawable.kofi, támogassminket),
        NavigationItem("Kapcsolat", Res.drawable.link, kapcsolat),
    )

    NavigationRail(
        modifier = Modifier.width(180.dp).padding(8.dp)//ha telefon akkor ne legyen ennyire széles
    ) {

        LaunchedEffect(Unit) {
            selectedItem = 0
            delay(1000)

          /*  println("viewport height = ${viewportHeight.value}")
            println("scroll state = ${scrollState.value}")
            println("programrol = ${programrol.value}")
            println("elismeresekOffset = ${elismeresekOffset.value}")
            println("letoltes = ${letoltes.value}")*/
        }

        LaunchedEffect(scrollState.value) {
            val tolerance = 60
            // Calculate the center of the viewport
            val viewportCenter = viewportHeight.value / 2
          //  println("viewportcenter = $viewportCenter")
           // println("scroll state = ${scrollState.value}")
            items.forEachIndexed { index, navigationItem ->
                // Adjust the condition to check if the item is near the center of the viewport
                val itemCenterPosition = navigationItem.offset.value - 60 //+ viewportCenter
                if (
                    scrollState.value in (itemCenterPosition - tolerance)..(itemCenterPosition + tolerance)
                ) {
                    selectedItem = index
                }
            }
            if (scrollState.value == 0) {
                selectedItem = 0
            }
        }



        items.forEachIndexed { index, item ->
            NavigationRailItem(
                icon = {
                    Icon(
                        painterResource(item.icon),
                        contentDescription = item.label,
                        modifier = Modifier.size(40.dp),
                        tint = if (selectedItem == index) matekitcolor else LocalContentColor.current
                    )
                },
                label = { Text(item.label, fontSize = 16.sp) },
                selected = selectedItem == index,
                onClick = {
                    selectedItem = index

                    coroutineScope.launch {
                        when (item.label) {
                            "" -> scrollState.animateScrollTo(
                                0, SpringSpec(
                                    dampingRatio = Spring.DampingRatioLowBouncy,
                                    stiffness = Spring.StiffnessLow
                                )
                            )
                            "Webalkalmazás" -> scrollState.animateScrollTo(
                                webappoffset.value, SpringSpec(
                                    dampingRatio = Spring.DampingRatioLowBouncy,
                                    stiffness = Spring.StiffnessLow
                                )
                            )

                            "Elismerések" -> scrollState.animateScrollTo(
                                elismeresekOffset.value, SpringSpec(
                                    dampingRatio = Spring.DampingRatioLowBouncy,
                                    stiffness = Spring.StiffnessLow
                                )
                            )

                            "Letöltések" -> {
                                scrollState.animateScrollTo(
                                    letoltes.value,
                                    SpringSpec(
                                        dampingRatio = Spring.DampingRatioLowBouncy,
                                        stiffness = Spring.StiffnessLow
                                    )
                                )
                                //println("letoltes scroll = ${letoltes.value}")
                            }

                            "A Programról" -> scrollState.animateScrollTo(
                                programrol.value, SpringSpec(
                                    dampingRatio = Spring.DampingRatioLowBouncy,
                                    stiffness = Spring.StiffnessLow
                                )
                            )

                            "Feladatgenerátorok" -> scrollState.animateScrollTo(
                                feladatgenerátorokscroll.value, SpringSpec(
                                    dampingRatio = Spring.DampingRatioLowBouncy,
                                    stiffness = Spring.StiffnessLow
                                )
                            )

                            "Játékok" -> scrollState.animateScrollTo(
                                gamesscroll.value, SpringSpec(
                                    dampingRatio = Spring.DampingRatioLowBouncy,
                                    stiffness = Spring.StiffnessLow
                                )
                            )

                            "Ajánlások" -> scrollState.animateScrollTo(
                                ajanlasok.value, SpringSpec(
                                    dampingRatio = Spring.DampingRatioLowBouncy,
                                    stiffness = Spring.StiffnessLow
                                )
                            )

                            "Vélemények" -> scrollState.animateScrollTo(
                                velemenyek.value, SpringSpec(
                                    dampingRatio = Spring.DampingRatioLowBouncy,
                                    stiffness = Spring.StiffnessLow
                                )
                            )

                            "Rólunk" -> scrollState.animateScrollTo(
                                rolunk.value, SpringSpec(
                                    dampingRatio = Spring.DampingRatioLowBouncy,
                                    stiffness = Spring.StiffnessLow
                                )
                            )

                            "Támogass minket!" -> scrollState.animateScrollTo(
                                támogassminket.value, SpringSpec(
                                    dampingRatio = Spring.DampingRatioLowBouncy,
                                    stiffness = Spring.StiffnessLow
                                )
                            )

                            "Kapcsolat" -> scrollState.animateScrollTo(
                                kapcsolat.value, SpringSpec(
                                    dampingRatio = Spring.DampingRatioLowBouncy,
                                    stiffness = Spring.StiffnessLow
                                )
                            )
                        }
                    }

                },
                //telefonon kisebb ikonok
            )
        }
    }
}


//data class NavigationItem(val label: String, val icon: DrawableResource)
data class NavigationItem(
    val label: String,
    val icon: DrawableResource,
    val offset: MutableState<Int>
)