package matekit.website.ui

import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.darkColorScheme
import androidx.compose.runtime.Composable



private   val colorScheme = darkColorScheme(
)

@Composable
fun pageTheme(
    content: @Composable () -> Unit

){


    MaterialTheme(
     colorScheme= colorScheme,
        content = content
    )
}