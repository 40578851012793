package matekit.website.ui

import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp

data class ratios( //alapértelmezett értek az asztaliak
    val horizontal: Float =.6f,
    val space:Modifier = Modifier.height(64.dp).width(64.dp),
    val halfspace:Modifier = Modifier.height(32.dp).width(32.dp),
    val largeTitle:TextUnit = 72.sp,
    val largeTitleheight:TextUnit = 80.sp,
    val titlefontsize:TextUnit = 44.sp,
    val titlelineheight:TextUnit = 52.sp,
    val secondaryfontsize :TextUnit= 32.sp,
    val secondarylineheight:TextUnit = 40.sp,
    val tertiaryfontsize:TextUnit = 24.sp,
    val tertiarylineheight:TextUnit = 32.sp,
    val elrendezes:elrendezes  = matekit.website.ui.elrendezes.Horizontal,
    val widthtype:widthtype = matekit.website.ui.widthtype.large,
    val largepadding:Dp = 64.dp,
    val padding: Dp =32.dp,
    val smallpadding : Dp = 16.dp,
    val textAlign: TextAlign = TextAlign.Justify
)

val smalldeviceratios =ratios(
    horizontal = .95f,
    space = Modifier.height(32.dp).width(32.dp),
    halfspace = Modifier.height(16.dp).width(16.dp),
    largeTitle = 60.sp,
    largeTitleheight = 64.sp,
    titlefontsize = 36.sp,
    titlelineheight = 40.sp,
    secondaryfontsize = 24.sp,
    secondarylineheight = 28.sp,
    tertiaryfontsize = 16.sp,
    tertiarylineheight = 18.sp,
    elrendezes = elrendezes.Vertical,
    widthtype = matekit.website.ui.widthtype.small,
    largepadding = 10.dp,
    padding = 8.dp,
    smallpadding = 3.dp,
    textAlign = TextAlign.Justify


)
val mediumdeviceratios =ratios(
    horizontal = .8f,
    elrendezes = elrendezes.Vertical,
    widthtype = matekit.website.ui.widthtype.medium
)


enum class widthtype{
    small,
    medium,
    large
}
enum class elrendezes{
    Horizontal,
    Vertical
}