@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package matekitwebsite.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val BuyMeACoffee: DrawableResource by 
      lazy { init_BuyMeACoffee() }

  public val KovacsJanos: DrawableResource by 
      lazy { init_KovacsJanos() }

  public val KovacsJanos_circle: DrawableResource by 
      lazy { init_KovacsJanos_circle() }

  public val Melinda_circle: DrawableResource by 
      lazy { init_Melinda_circle() }

  public val Melinda_pfp: DrawableResource by 
      lazy { init_Melinda_pfp() }

  public val calculate: DrawableResource by 
      lazy { init_calculate() }

  public val compose_multiplatform: DrawableResource by 
      lazy { init_compose_multiplatform() }

  public val controller: DrawableResource by 
      lazy { init_controller() }

  public val description: DrawableResource by 
      lazy { init_description() }

  public val download: DrawableResource by 
      lazy { init_download() }

  public val download_24dp: DrawableResource by 
      lazy { init_download_24dp() }

  public val facebook_logo: DrawableResource by 
      lazy { init_facebook_logo() }

  public val forum: DrawableResource by 
      lazy { init_forum() }

  public val group: DrawableResource by 
      lazy { init_group() }

  public val home: DrawableResource by 
      lazy { init_home() }

  public val ic_android_black_24dp: DrawableResource by 
      lazy { init_ic_android_black_24dp() }

  public val ic_gl7: DrawableResource by 
      lazy { init_ic_gl7() }

  public val ifj_verseny: DrawableResource by 
      lazy { init_ifj_verseny() }

  public val kofi: DrawableResource by 
      lazy { init_kofi() }

  public val link: DrawableResource by 
      lazy { init_link() }

  public val mail_24dp: DrawableResource by 
      lazy { init_mail_24dp() }

  public val matecat: DrawableResource by 
      lazy { init_matecat() }

  public val otp_fay_innovaciosdij: DrawableResource by 
      lazy { init_otp_fay_innovaciosdij() }

  public val otp_fay_innovaciosdij_small: DrawableResource by 
      lazy { init_otp_fay_innovaciosdij_small() }

  public val play_arrow_24dp: DrawableResource by 
      lazy { init_play_arrow_24dp() }

  public val reviews: DrawableResource by 
      lazy { init_reviews() }

  public val support_me_on_kofi_red: DrawableResource by 
      lazy { init_support_me_on_kofi_red() }

  public val sziiv_logo_nagy: DrawableResource by 
      lazy { init_sziiv_logo_nagy() }

  public val trophy: DrawableResource by 
      lazy { init_trophy() }

  public val windows_logo___2021: DrawableResource by 
      lazy { init_windows_logo___2021() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("BuyMeACoffee", CommonMainDrawable0.BuyMeACoffee)
  map.put("KovacsJanos", CommonMainDrawable0.KovacsJanos)
  map.put("KovacsJanos_circle", CommonMainDrawable0.KovacsJanos_circle)
  map.put("Melinda_circle", CommonMainDrawable0.Melinda_circle)
  map.put("Melinda_pfp", CommonMainDrawable0.Melinda_pfp)
  map.put("calculate", CommonMainDrawable0.calculate)
  map.put("compose_multiplatform", CommonMainDrawable0.compose_multiplatform)
  map.put("controller", CommonMainDrawable0.controller)
  map.put("description", CommonMainDrawable0.description)
  map.put("download", CommonMainDrawable0.download)
  map.put("download_24dp", CommonMainDrawable0.download_24dp)
  map.put("facebook_logo", CommonMainDrawable0.facebook_logo)
  map.put("forum", CommonMainDrawable0.forum)
  map.put("group", CommonMainDrawable0.group)
  map.put("home", CommonMainDrawable0.home)
  map.put("ic_android_black_24dp", CommonMainDrawable0.ic_android_black_24dp)
  map.put("ic_gl7", CommonMainDrawable0.ic_gl7)
  map.put("ifj_verseny", CommonMainDrawable0.ifj_verseny)
  map.put("kofi", CommonMainDrawable0.kofi)
  map.put("link", CommonMainDrawable0.link)
  map.put("mail_24dp", CommonMainDrawable0.mail_24dp)
  map.put("matecat", CommonMainDrawable0.matecat)
  map.put("otp_fay_innovaciosdij", CommonMainDrawable0.otp_fay_innovaciosdij)
  map.put("otp_fay_innovaciosdij_small", CommonMainDrawable0.otp_fay_innovaciosdij_small)
  map.put("play_arrow_24dp", CommonMainDrawable0.play_arrow_24dp)
  map.put("reviews", CommonMainDrawable0.reviews)
  map.put("support_me_on_kofi_red", CommonMainDrawable0.support_me_on_kofi_red)
  map.put("sziiv_logo_nagy", CommonMainDrawable0.sziiv_logo_nagy)
  map.put("trophy", CommonMainDrawable0.trophy)
  map.put("windows_logo___2021", CommonMainDrawable0.windows_logo___2021)
}

internal val Res.drawable.BuyMeACoffee: DrawableResource
  get() = CommonMainDrawable0.BuyMeACoffee

private fun init_BuyMeACoffee(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:BuyMeACoffee",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/BuyMeACoffee.png", -1, -1),
    )
)

internal val Res.drawable.KovacsJanos: DrawableResource
  get() = CommonMainDrawable0.KovacsJanos

private fun init_KovacsJanos(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:KovacsJanos",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/KovacsJanos.jpg", -1, -1),
    )
)

internal val Res.drawable.KovacsJanos_circle: DrawableResource
  get() = CommonMainDrawable0.KovacsJanos_circle

private fun init_KovacsJanos_circle(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:KovacsJanos_circle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/KovacsJanos_circle.png", -1, -1),
    )
)

internal val Res.drawable.Melinda_circle: DrawableResource
  get() = CommonMainDrawable0.Melinda_circle

private fun init_Melinda_circle(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:Melinda_circle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/Melinda_circle.png", -1, -1),
    )
)

internal val Res.drawable.Melinda_pfp: DrawableResource
  get() = CommonMainDrawable0.Melinda_pfp

private fun init_Melinda_pfp(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:Melinda_pfp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/Melinda_pfp.jpg", -1, -1),
    )
)

internal val Res.drawable.calculate: DrawableResource
  get() = CommonMainDrawable0.calculate

private fun init_calculate(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:calculate",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/calculate.xml", -1, -1),
    )
)

internal val Res.drawable.compose_multiplatform: DrawableResource
  get() = CommonMainDrawable0.compose_multiplatform

private fun init_compose_multiplatform(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:compose_multiplatform",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/compose-multiplatform.xml", -1, -1),
    )
)

internal val Res.drawable.controller: DrawableResource
  get() = CommonMainDrawable0.controller

private fun init_controller(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:controller",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/controller.xml", -1, -1),
    )
)

internal val Res.drawable.description: DrawableResource
  get() = CommonMainDrawable0.description

private fun init_description(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/description.xml", -1, -1),
    )
)

internal val Res.drawable.download: DrawableResource
  get() = CommonMainDrawable0.download

private fun init_download(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:download",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/download.xml", -1, -1),
    )
)

internal val Res.drawable.download_24dp: DrawableResource
  get() = CommonMainDrawable0.download_24dp

private fun init_download_24dp(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:download_24dp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/download_24dp.xml", -1, -1),
    )
)

internal val Res.drawable.facebook_logo: DrawableResource
  get() = CommonMainDrawable0.facebook_logo

private fun init_facebook_logo(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:facebook_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/facebook_logo.xml", -1, -1),
    )
)

internal val Res.drawable.forum: DrawableResource
  get() = CommonMainDrawable0.forum

private fun init_forum(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:forum",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/forum.xml", -1, -1),
    )
)

internal val Res.drawable.group: DrawableResource
  get() = CommonMainDrawable0.group

private fun init_group(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:group",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/group.xml", -1, -1),
    )
)

internal val Res.drawable.home: DrawableResource
  get() = CommonMainDrawable0.home

private fun init_home(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:home",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/home.xml", -1, -1),
    )
)

internal val Res.drawable.ic_android_black_24dp: DrawableResource
  get() = CommonMainDrawable0.ic_android_black_24dp

private fun init_ic_android_black_24dp(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_android_black_24dp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/ic_android_black_24dp.xml", -1, -1),
    )
)

internal val Res.drawable.ic_gl7: DrawableResource
  get() = CommonMainDrawable0.ic_gl7

private fun init_ic_gl7(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_gl7",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/ic_gl7.xml", -1, -1),
    )
)

internal val Res.drawable.ifj_verseny: DrawableResource
  get() = CommonMainDrawable0.ifj_verseny

private fun init_ifj_verseny(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ifj_verseny",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/ifj_verseny.jpg", -1, -1),
    )
)

internal val Res.drawable.kofi: DrawableResource
  get() = CommonMainDrawable0.kofi

private fun init_kofi(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:kofi",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/kofi.xml", -1, -1),
    )
)

internal val Res.drawable.link: DrawableResource
  get() = CommonMainDrawable0.link

private fun init_link(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:link",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/link.xml", -1, -1),
    )
)

internal val Res.drawable.mail_24dp: DrawableResource
  get() = CommonMainDrawable0.mail_24dp

private fun init_mail_24dp(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:mail_24dp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/mail_24dp.xml", -1, -1),
    )
)

internal val Res.drawable.matecat: DrawableResource
  get() = CommonMainDrawable0.matecat

private fun init_matecat(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:matecat",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/matecat.jpg", -1, -1),
    )
)

internal val Res.drawable.otp_fay_innovaciosdij: DrawableResource
  get() = CommonMainDrawable0.otp_fay_innovaciosdij

private fun init_otp_fay_innovaciosdij(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:otp_fay_innovaciosdij",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/otp_fay_innovaciosdij.png", -1, -1),
    )
)

internal val Res.drawable.otp_fay_innovaciosdij_small: DrawableResource
  get() = CommonMainDrawable0.otp_fay_innovaciosdij_small

private fun init_otp_fay_innovaciosdij_small(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:otp_fay_innovaciosdij_small",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/otp_fay_innovaciosdij_small.png", -1, -1),
    )
)

internal val Res.drawable.play_arrow_24dp: DrawableResource
  get() = CommonMainDrawable0.play_arrow_24dp

private fun init_play_arrow_24dp(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:play_arrow_24dp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/play_arrow_24dp.xml", -1, -1),
    )
)

internal val Res.drawable.reviews: DrawableResource
  get() = CommonMainDrawable0.reviews

private fun init_reviews(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:reviews",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/reviews.xml", -1, -1),
    )
)

internal val Res.drawable.support_me_on_kofi_red: DrawableResource
  get() = CommonMainDrawable0.support_me_on_kofi_red

private fun init_support_me_on_kofi_red(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:support_me_on_kofi_red",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/support_me_on_kofi_red.png", -1, -1),
    )
)

internal val Res.drawable.sziiv_logo_nagy: DrawableResource
  get() = CommonMainDrawable0.sziiv_logo_nagy

private fun init_sziiv_logo_nagy(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:sziiv_logo_nagy",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/sziiv_logo_nagy.png", -1, -1),
    )
)

internal val Res.drawable.trophy: DrawableResource
  get() = CommonMainDrawable0.trophy

private fun init_trophy(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:trophy",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/trophy.xml", -1, -1),
    )
)

internal val Res.drawable.windows_logo___2021: DrawableResource
  get() = CommonMainDrawable0.windows_logo___2021

private fun init_windows_logo___2021(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:windows_logo___2021",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekitwebsite.composeapp.generated.resources/drawable/windows_logo___2021.xml", -1, -1),
    )
)
