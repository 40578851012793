package matekit.website

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.dp
import matekit.website.ui.matekitcolor
import matekit.website.ui.ratios
import matekit.website.ui.widthtype
import matekitwebsite.composeapp.generated.resources.BasicOperations
import matekitwebsite.composeapp.generated.resources.ConvertUnits
import matekitwebsite.composeapp.generated.resources.FunctionReading
import matekitwebsite.composeapp.generated.resources.GeometryBlindMap
import matekitwebsite.composeapp.generated.resources.GeometryTaskGenerator
import matekitwebsite.composeapp.generated.resources.GetToKnowTheCoordinateSystem
import matekitwebsite.composeapp.generated.resources.MathKing
import matekitwebsite.composeapp.generated.resources.PracticeSolvingEquationSystems
import matekitwebsite.composeapp.generated.resources.PracticingEquations
import matekitwebsite.composeapp.generated.resources.PractisingFractions
import matekitwebsite.composeapp.generated.resources.Res
import matekitwebsite.composeapp.generated.resources.angle_estimation
import matekitwebsite.composeapp.generated.resources.exponentiation
import matekitwebsite.composeapp.generated.resources.percentage
import matekitwebsite.composeapp.generated.resources.practiceRomanNumerals
import matekitwebsite.composeapp.generated.resources.primefactors
import matekitwebsite.composeapp.generated.resources.proportionality
import matekitwebsite.composeapp.generated.resources.title_activity_algebrai_azonossagok
import matekitwebsite.composeapp.generated.resources.title_activity_convert_number_base
import matekitwebsite.composeapp.generated.resources.title_activity_function_analysis
import matekitwebsite.composeapp.generated.resources.title_activity_geometria_kockas_lapon
import matekitwebsite.composeapp.generated.resources.title_activity_kombinatorika
import matekitwebsite.composeapp.generated.resources.title_activity_magic_square
import matekitwebsite.composeapp.generated.resources.title_activity_number_pyramid
import matekitwebsite.composeapp.generated.resources.title_activity_sorozatok
import matekitwebsite.composeapp.generated.resources.title_activity_statisztika
import org.jetbrains.compose.resources.StringResource
import org.jetbrains.compose.resources.stringResource
import kotlin.math.abs

val feladatgenerátorok = listOf(
    modul(
        Res.string.title_activity_statisztika,
        "Általános iskolában valójában csak épp annyi statisztikát tanítunk, amennyi kell a központi felvételihez. Ezért készítettünk egy válogatást az elmúlt tizenöt év központi feladatsorainak statisztikai feladattípusaiból. A program véletlenszerűen választ a feladatok közül, véletlenszerűen generál hozzá értelmes számokat, majd tesz fel kérdéseket."
    ),
    modul(
        Res.string.BasicOperations,
        "Lehetőség van a négy alapművelettel való számolások gyakorlására is. Több paramétert változtathatunk, de akár előre elkészített sablonok közül is választhatunk."
    ),
    modul(
        Res.string.PractisingFractions,
        "A modul négy nehézségi fokozatot tartalmaz, de a paraméterek egyenként is állíthatóak. A diák eldöntheti, hogy szeretne-e vegyes számokat, ha igen akkor milyen tartományban. A törtrészben szereplő számok ettől függetlenül variálhatóak. Megadhatjuk a kiszámítandó művelet tagszámát, vagy akár véletlenszerű hosszúságú feladatot is kérhetünk. Eldönthetjük, hogy az eredményt a program bármilyen formában, vagy csak a legegyszerűbb tört alakban fogadja el."
    ),
    modul(
        Res.string.PracticingEquations,
        "Az egyenletmegoldást hét típusfeladaton keresztül tanítjuk általános iskolában, a program mindegyik fajtát képes legenerálni. A típusválasztás helyett megjeleníthetünk egy rajztáblát, így például egy csak digitális táblával felszerelt osztályban van hova kidolgozni a feladatokat."
    ),
    modul(
        Res.string.PracticeSolvingEquationSystems,
        "Kétismeretlenes lineáris egyenletrendszereket is tud készíteni az alkalmazás"
    ),
    modul(
        Res.string.practiceRomanNumerals,
        "Gyakorolhatjuk az arabból római vagy rómaiból arab számokba való átváltást egy egytől négyezerig választható intervallumban."
    ),
    modul(
        Res.string.proportionality,
        "A százalékos feladatok bevezetői. A program előre megírt szöveges feladatokhoz generál kontextusba illő számokat, így számtalan lehetséges példát készítve."
    ),
    modul(
        Res.string.percentage,
        "Fontos része az általános iskolás tananyagnak, a hétköznapi életben is sokszor szükségünk van rá. A modul 10 féle típusfeladatot tartalmaz, különféle szövegváltozatokkal."
    ),
    modul(
        Res.string.ConvertUnits,
        "A tanuló kiválaszthatja, hogy mely mértékegységek váltását szeretné gyakorolni, illetve maximum milyen távol legyenek azok egymástól. (pl: gramm és dekagramm közti váltást kérdező feladat sokkal gyakorlatiasabb, mint köbmiliméter és köbkilométer köztit kérdező)"
    ),
    modul(
        Res.string.GeometryTaskGenerator,
        "A 12 leggyakoribb alakzat terület, kerület és térfogatszámítási feladatait képes legenerálni. Ezen kívül az alapadatokból készíthető minden lehetséges feladatkombinációt tartalmazza. (pl. a terület ismeretében az ismeretlen oldal hosszát kérdezi)"
    ),
    modul(
        Res.string.title_activity_geometria_kockas_lapon,
        "Négyzethálóra rajzol síkidomokat, melyeknek a területét kell a diákoknak kiszámolni."
    ),
    modul(
        Res.string.GetToKnowTheCoordinateSystem,
        "A koordinátarendszer X,Y tengelyeinek és a pozitív, negatív számok helyének a megjegyzése sok diáknak okoz nehézséget a téma elején. Ez a feladatgenerátor két típusfeladaton keresztül segít gyakorolni. Pontbejelölés estén a megadott koordinátájú pontra kell kattintani. Vagy a diák gyakorolhatja egy pont koordinátáinak leolvasását. A program itt is naplózza a feladatmegoldásokat."
    ),
    modul(
        Res.string.FunctionReading,
        "Fontos, hogy a diákok könnyen le tudjanak olvasni egy lineáris függvényt, ezért ennek gyakorlására is készült egy modul. A koordinátarendszerben ábrázolt függvény képletét kell a tanulónak megadnia."
    ),
    modul(
        Res.string.title_activity_function_analysis,
        "A Függvényleolvasás modul párja, képlettel megadott függvénnyel kapcsolatos kérdésekre kell a tanulónak válaszolnia."
    ),
    modul(
        Res.string.primefactors,
        "A diákok gyakorolhatják véletlenszerűen generált számok prímtényezős felbontásának elkészítését."
    ),
    modul(
        Res.string.title_activity_kombinatorika,
        "A kombinatorikai feladatgenerátor az általános iskolában tanított 6 fő feladattípust tudja elkészíteni. Az egyes típusfeladatokhoz különféle feladatszövegek közül választ."
    ),
    modul(Res.string.title_activity_convert_number_base,"A diákok gyakorolhatják az oda-vissza váltást különböző számrendszerek között. Egyszerűen kiválaszthatják 2 és 16 között a gyakorolni kívánt számrendszer alapszámát."),
    modul(Res.string.title_activity_sorozatok, "Számtani, mértani, és néhány periodikus sorozathoz tartozó típusfeladatokat gyakorlását segíti ez a modul."),
    modul(Res.string.title_activity_algebrai_azonossagok, "Az algebrai azonosságok kilencedikben szinte mindenütt nagyon nehéz anyagnak bizonyul. Túl kevés rá az idő, itt csúszunk el időben az anyaggal és még annál is többet rá kellene szánni. Nyolcadikban a felvételi után viszont megáll az élet, nehéz bármilyen munkára rávenni a diákokat. Ha viszont elmeséljük nekik, hogy kilencedikben sokan emiatt buknak meg, akkor rá lehet őket venni, hogy nyugodtan, kényelmesen begyakorolják a témát - így jobban indulnak majd az új iskolában. Az Algebrai Azonosságok Modulban gyakorlatilag a kilencedikes feladattípusok mindegyike szerepel. A gyakorló feladatokban, hogy képernyőn ne kelljen sok mindent bevinni, mindig csak néhány számot - együtthatókat, kitevőket kell megadni. Viszont a modul párjaként kidolgozott Dolgozatgenerátor tud teljes hagyományos feladatsort-könnyebbet és nehezebbet - is generálni több típus összekeverésével, melynek természetesen a megoldókulcsa is elérhető. Erre azért volt szükség, mert ez egy hagyományos, papírt, írószert igénylő téma, ahol az egyes típusok megtévesztően könnyűnek tűnnek, azonban ha össze vannak keverve, akkor sokaknak gondot okoznak.")
)

val games = listOf(
    modul(
        Res.string.angle_estimation,
        "A Szögbecslés három játékot tartalmaz. Az elsőben a diáknak kell megbecsülnie egy a program által rajzolt szög nagyságát. Egy másik változatban a tanulónak kell megrajzolnia a program által kért szöget. A harmadikban a program folyamatosan rajzol egy szöget, adott foknál kell kattintania a tanulónak. Ez egy jó lehetőség a szögmérő használatának gyakorlására is."
    ),
    modul(
        Res.string.exponentiation,
        "A hatványok gyakorlására is készült egy játék. Az egyes számok hatványainak a nagyságrendjének a megismerése megalapozza a különböző számrendszerek témakörét"
    ),
    modul(
        Res.string.title_activity_number_pyramid,
        "A számpiramis egy már régóta sikeres matematikai játék. Az alkalmazás ennek is tartalmazza a digitalizált verzióját. A diákok választhatnak, hogy összeadást, vagy szorzást szeretnének gyakorolni, megadhatják, hogy hány emeletes legyen a piramis, és hogy milyen tartományban generálja a számokat."
    ),
    modul(
        Res.string.MathKing,
        "A Számkirály a már évek óta sikeres pedagógiai játék okoseszközökre adaptált verziója. Lényege, hogy két tanuló egymással versenyezve old meg számolási feladatokat, aki több kört nyer, az győz. A programban különböző típusú számolásokat lehet gyakoroltatni a kezdési paraméterek megváltoztatásával. A tízes számkörben való összeadásoktól egészen fejszámoló háziversenyek lebonyolításáig mindenre alkalmas. A program törekszik értelmes, nem túlságosan nehéz feladatok készítésére. A játék végén megtekinthető, hogy az egyes körökben ki milyen válaszokat adott, mi lett volna a helyes megoldás.  A felhasználói felületnek van álló és fekvő változata, így a diákok elhelyezkedhetnek egymással szemben vagy egymás mellett is. "
    ),
    modul(
        Res.string.title_activity_magic_square,
        "Bűvös négyzeteket tud generálni és természetesen ki is javítja a diákok munkáját. A diákok kiválaszthatják, hány ismeretlen szám legyen a bűvös négyzetben, illetve milyen intervallumban generálja őket a program."
    ),
    modul(
        Res.string.GeometryBlindMap,
        "A Geometriai Vaktérkép szórakoztató módon tanítja meg a főbb síkidomokat. A játék során a program véletlenszerűen választ a 14 síkidom közül, megjeleníti a nevét, a játékos célja pedig az, hogy minél gyorsabban rákattintson. A játékos folyamatos visszajelzést kap a pontosságáról, igénybe veheti a súgót, különböző nehézségi szintekkel pedig nagyobb kihívást vállalhat. Ha a játékos egyből eltalálja a megfelelő síkidomot, akkor azt zöldre színezi a program, ha néhányat téveszt, akkor sárgára, ha pedig sokadjára sem találja meg, akkor pirosra, és kapja a következőt."
    )
)

@Composable
fun CustomGrid(modules: List<modul>, selectedindex: MutableState<Int>, ratios:ratios) {
    val chunksize by derivedStateOf {
        when (ratios.widthtype){
            widthtype.small -> 1
            widthtype.medium -> 3
            widthtype.large -> 4
        }
    }
    Column(modifier = Modifier.fillMaxWidth(0.95f)) {
        modules.chunked(chunksize).forEach { rowItems ->
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.Top,
                horizontalArrangement = Arrangement.Center
            ) {
                rowItems.forEach { module ->
                    Box(modifier = Modifier.weight(1f)) {
                        FlippableModuleCard(
                            module.title,
                            module.description,
                            modules.indexOf(module),
                            selectedindex
                        )
                    }
                }
                repeat(chunksize - rowItems.size) {
                    Spacer(modifier = Modifier.weight(1f))
                }
            }
        }
    }
}

@Composable
fun ModuleCard(
    title: StringResource,
    description: String,
    index: Int,
    selectedindex: MutableState<Int>,
    secondaryfontsize: TextUnit,
    secondarylineheight: TextUnit
) {
    val title = stringResource(title)
    val size = derivedStateOf {
        if (selectedindex.value == index) 1600.dp
        else 400.dp

    }


    AnimatedVisibility(
        selectedindex.value == index || selectedindex.value == 86
    ) {


        Card(
            colors = CardDefaults.cardColors(
                containerColor = matekitcolor,
            ),
            modifier = Modifier.clickable {
                if (selectedindex.value == index) {
                    selectedindex.value = 86
                } else {
                    selectedindex.value = index
                }
            }.size(size.value).padding(12.dp)
        ) {
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center,
                modifier = Modifier.padding(16.dp)
                    .fillMaxSize()

            ) {
                if (selectedindex.value == index) Text(
                    description, fontSize = secondaryfontsize,
                    lineHeight = secondarylineheight
                )
                else {
                    Text(
                        title,
                        fontSize = secondaryfontsize,
                        lineHeight = secondarylineheight,
                        textAlign = TextAlign.Center
                    )
                }

            }


        }
    }

}


@Composable
fun FlippableModuleCard(
    title: StringResource,
    description: String,
    index: Int,
    selectedindex: MutableState<Int>
) {
    var rotationAngle by remember { mutableStateOf(0f) }
    val animatedRotation by animateFloatAsState(targetValue = rotationAngle)

    Card(
        modifier = Modifier
            .padding(12.dp)
            // .size(400.dp)
            .fillMaxWidth()
            .clickable {
                rotationAngle = if (rotationAngle == 0f) 180f else 0f
            }
            .graphicsLayer {
                rotationY = animatedRotation
                cameraDistance = 12.dp.toPx()
            }
            .clip(RoundedCornerShape(8.dp)),
        colors = CardDefaults.cardColors(
            containerColor = matekitcolor
        )
    ) {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .padding(16.dp)
        ) {
            val normalizedAngle = abs(animatedRotation % 360f)
            val isBackVisible = normalizedAngle > 90f && normalizedAngle < 270f


            if (isBackVisible) {
                Text(
                    text = description,
                    color = Color.White,
                    modifier = Modifier
                        .align(Alignment.Center)
                        .graphicsLayer {
                            rotationY = 180f // Correct the mirrored text
                        }
                )
            } else {
                Text(
                    text = stringResource(title),
                    color = Color.White,
                    modifier = Modifier.align(Alignment.Center)
                )
            }
        }
    }
}


data class modul(
    val title: StringResource,
    val description: String = "Lorem ipsum"
)